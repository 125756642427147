// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useEffect, useState } from "react"
import { AppButton, AppInput, HomeHeader } from "../../components"
import { Grid, Typography, Checkbox } from "@mui/material"
import eyeIcon from "../../assets/svg/eye.svg"
import eyeOff from "../../assets/svg/eyeOff.svg"
import { ReactComponent as EmailIcon } from "../../assets/svg/email.svg"
import { ReactComponent as PasswordIcon } from "../../assets/svg/password.svg"
import { ReactComponent as PlayerSignup } from "../../assets/svg/PlayerSignup.svg"
import { ReactComponent as ParentSignup } from "../../assets/svg/ParentSignup.svg"
import { ReactComponent as OrganizationSignup } from "../../assets/svg/OrganizationSignup.svg"
import { ReactComponent as CoachSignup } from "../../assets/svg/CoachSignup.svg"
import BadgeIcon from '@mui/icons-material/Badge';
import { useNavigate } from "react-router-dom"
import { signupUser } from "../../api/auth"
import AppContext from "../../Context"
import { useContext } from "react"
import { useSnackbar } from "notistack"
import { COLORS } from "../../constants"

export default function Signup({ }) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { user, setUser, _getProfile } = useContext(AppContext)
  const path = window.location.pathname
  const [state, setState] = useState({
    activeTab: 0,
    showValidationMessage: false,
    email: "",
    password: "",
    password: "",
    c_password: "",
    loading: false,
    selectedUser: "",
    showPassword: false,
    showPassword1: false,
    checked: false,
    first_name: "",
    last_name: "",
    username: '',
  })

  useEffect(() => {
    if (user?.is_2fa) {
      navigate("/")
    }
    if (user && !user?.is_2fa) {
      navigate("/login")
    }
  }, [path, user])

  const {
    email,
    password,
    c_password,
    loading,
    checked,
    showPassword,
    showPassword1,
    selectedUser,
    showValidationMessage,
    first_name,
    last_name,
    username,
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({
      ...pre,
      [key]: value
    }))
  }

  const handleSignup = async () => {
    try {
      handleChange("loading", true)
      const payload = {
        email,
        first_name,
        last_name,
        username,
        password,
        role: selectedUser
      }
      const res = await signupUser(payload)
      localStorage.setItem("token", res?.data?.token)
      localStorage.setItem("user", JSON.stringify(res?.data))
      handleChange("loading", false)
      _getProfile(res?.data?.id)
      setUser(res?.data)
      navigate("/2fa-verfition")
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      if (errorText.length > 0) {
        alert(`Error: ${errorText[0]}`)
      } else {
        alert(`Error: ${error}`)
      }
    }
  }

  return (
    <div>
      <HomeHeader />
      <section>
        <div className="container loginContainer">
          <Grid container justifyContent={"center"}>
            {selectedUser === "" ? (
              <Grid item xs={10} sm={10} md={6} lg={4} className="">
                <div className="text-left">
                  <div className="font-24 font-bold greyColor">
                    Select your role
                  </div>
                  <div className="light-grey-text font-bold mb-3">
                    Would you like to join a team, create a team or set up your
                    league?
                  </div>
                </div>
                <Grid>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      onClick={() => handleChange("selectedUser", "Player")}
                      className="cursor mb-2"
                    >
                      <PlayerSignup />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      onClick={() => handleChange("selectedUser", "Parent")}
                      className="cursor mb-2"
                    >
                      <ParentSignup />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      onClick={() =>
                        handleChange("selectedUser", "Organization")
                      }
                      className="cursor mb-2"
                    >
                      <OrganizationSignup />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      onClick={() => handleChange("selectedUser", "Coach")}
                      className="cursor mb-2"
                    >
                      <CoachSignup />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={10} sm={10} md={6} lg={4} className="">
                <div className="text-left">
                  <div className="font-24 greyColor">
                    Create your The Sports Portal account
                  </div>
                  <div className="greyColor">
                    Already have an account?
                    <span
                      onClick={() => navigate("/login")}
                      className="text_primary font-bold cursor"
                    >
                      {" "}
                      Log In
                    </span>
                  </div>
                </div>
                <AppInput
                  label={"Enter First Name"}
                  value={first_name}
                  prefix={
                    <div className="emailIcon">
                      <BadgeIcon style={{ fontSize: 14 }} />
                    </div>
                  }
                  name={"first_name"}
                  inputWidthFull
                  onChange={handleChange}
                  className="mb-3 mt-3"
                  placeholder={"Enter first name"}
                />
                <AppInput
                  label={"Enter Last Name"}
                  value={last_name}
                  prefix={
                    <div className="emailIcon">
                      <BadgeIcon style={{ fontSize: 14 }} />
                    </div>
                  }
                  name={"last_name"}
                  inputWidthFull
                  onChange={handleChange}
                  className="mb-3 mt-3"
                  placeholder={"Enter last name"}
                />
                <AppInput
                  label={"Enter Username"}
                  value={username}
                  prefix={
                    <div className="emailIcon">
                      <BadgeIcon style={{ fontSize: 14 }} />
                    </div>
                  }
                  name={"username"}
                  inputWidthFull
                  onChange={handleChange}
                  className="mb-3 mt-3"
                  placeholder={"Enter username"}
                />
                <AppInput
                  label={"Enter Email"}
                  value={email}
                  prefix={
                    <div className="emailIcon">
                      <EmailIcon />
                    </div>
                  }
                  name={"email"}
                  inputWidthFull
                  onChange={handleChange}
                  className="mb-3 mt-3"
                  placeholder={"Enter email"}
                />
                <AppInput
                  className="mb-4 mt-3"
                  value={password}
                  type={showPassword ? "text" : "password"}
                  inputWidthFull
                  prefix={
                    <div className="passwordIcon">
                      <PasswordIcon />
                    </div>
                  }
                  name={"password"}
                  onChange={handleChange}
                  label={"Create Password"}
                  postfix={
                    <img
                      src={!showPassword ? eyeIcon : eyeOff}
                      onClick={() =>
                        handleChange("showPassword", !showPassword)
                      }
                      width={20}
                      className={"c-pointer"}
                    />
                  }
                  placeholder={"********"}
                />
                {password && password?.length < 8 && (
                  <div className="validationmessage">
                    {"Password must have at least 8 characters"}
                  </div>
                )}
                <AppInput
                  className="mb-4 mt-3"
                  value={c_password}
                  type={showPassword1 ? "text" : "password"}
                  inputWidthFull
                  prefix={
                    <div className="passwordIcon">
                      <PasswordIcon />
                    </div>
                  }
                  name={"c_password"}
                  onChange={handleChange}
                  label={"Confirm Password"}
                  postfix={
                    <img
                      src={!showPassword1 ? eyeIcon : eyeOff}
                      onClick={() =>
                        handleChange("showPassword1", !showPassword1)
                      }
                      width={20}
                      className={"c-pointer"}
                    />
                  }
                  placeholder={"********"}
                />
                {password && password !== c_password && (
                  <div className="validationmessage">
                    {"Password must match"}
                  </div>
                )}
                <div className="checkboxDiv2 mb-3">
                  <Checkbox
                    checked={checked}
                    onClick={() => handleChange("checked", !checked)}
                    className="checkbox1"
                    style={{
                      marginTop: -3
                    }}
                    sx={{
                      color: "rgba(201, 208, 216, 1)",
                      "&.Mui-checked": {
                        color: COLORS.primary
                      }
                    }}
                  />
                  <Typography
                    variant="body2"
                    className="text_primary06 checkboxLabel"
                  >
                    I have read{" "}
                    <span
                      className="text_primary text-underline font-bold c-pointer"
                      onClick={() => window.open("https://app.termly.io/policy-viewer/policy.html?policyUUID=70b24cbf-084b-412a-8305-e4fca0acbe34", "_blank")}
                    >
                      Terms and Conditions
                    </span>{" "}
                    and{" "}
                    <span
                      className="text_primary text-underline font-bold c-pointer"
                      onClick={() => window.open("https://app.termly.io/policy-viewer/policy.html?policyUUID=cd25d1e5-02e0-43c1-a6cc-8252430a55bd", "_blank")}
                    >
                      Privacy Policy
                    </span>{" "}
                  </Typography>
                </div>
                <AppButton
                  title={"Sign up"}
                  className={"mb-5"}
                  inputWidthFull
                  onClick={handleSignup}
                  loading={loading}
                  disabled={
                    !email ||
                    !password ||
                    password?.length < 8 ||
                    password !== c_password ||
                    !checked
                  }
                  backgroundColor={COLORS.primary}
                  color={"#fff"}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </section>
    </div>
  )
}
