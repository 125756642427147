// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useState } from "react"
import {
  HomeHeader,
  AppButton,
  OrganizationTeamTable,
  AddReview,
  AddQuestionsReview,
  Loader,
  Tryout,
  Post,
  QRCode,
  FollowUser,
  ReportUser
} from "../../components"
import { Grid, Divider } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import AvatarIcon from "../../assets/images/avatar.png"
import { ReactComponent as ReportIcon } from "../../assets/svg/reportIcon.svg"
import {
  addFollow,
  addPlayerToFav,
  checkReviewStatus,
  createChannel,
  getAllPosts,
  getChannels,
  getOrganizationProfile,
  getOrganizationTeam,
  getPostById,
  getTargetPlayerLevel,
  getTargetTeamCompetition,
  getTeamTravel,
  getUserProfile,
  getUserProfileFollower,
  getUserProfileFollowing,
  removeFollow,
  removeFollower,
  removePlayerToFav,
  reviewsCatScore,
  showInterest
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import { useEffect } from "react"
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg"
import { COLORS } from "../../constants"
import SeeMoreText from "../../components/Text/SeeMoreText"
import { ReactComponent as MessageFill } from "../../assets/svg/MessageFill.svg"
import { getPriceRange, getUsers } from "../../api/admin"
import { ReactComponent as Tiktok } from "../../assets/svg/social/Tiktok.svg"
import { ReactComponent as Facebook } from "../../assets/svg/social/Facebook.svg"
import { ReactComponent as Insta } from "../../assets/svg/social/Insta.svg"
import { ReactComponent as Twitter } from "../../assets/svg/social/Twitter.svg"
import { ReactComponent as Youtube } from "../../assets/svg/youtube.svg"
import { ReactComponent as QRcodeIcon } from "../../assets/svg/qrcode.svg"
import { ReactComponent as Threads } from "../../assets/svg/threads.svg"
import { ReactComponent as StarFill } from "../../assets/svg/StarFill.svg"
import { ReactComponent as FavWhite } from "../../assets/svg/favorite.svg"
import { ReactComponent as UnFavWhite } from "../../assets/svg/favorite.svg"
import { gotoLink } from "../../utils/mixed"
import BlockUser from "../../components/GeneralModal/BlockUser"

export default function OrganizationProfile({ }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const token = localStorage.getItem("token")
  const [apiCallsMade, setApiCallsMade] = useState(false);
  const { user, isPlayer, isParent } = useContext(AppContext)
  const [state, setState] = useState({
    showAll: false,
    reviewVisible: false,
    statsVisible: false,
    priceRanges: [],
    lowPriceSign: "",
    highPriceSign: "",
    showComment: "",
    teams: [],
    posts: [],
    questionReviews: [],
    questionReviewsModal: [],
    videoPlaying: "",
    reviewStatus: false,
    questionsReviewVisible: false,
    loading: false,
    organizationData: null,
    organizationUserData: null,
    showQRCode: false,
    teamTravel: [],
    loadingChat: false,
    targetPlayerLevel: [],
    targetTeamCompetition: [],
    allChannels: [],
    allUsers: [],
    loadingInterest: false,
    loadingFollow: false,
    followVisible: false,
    followVisibleType: "",
    loadingRemove: false,
    userProfileFollower: [],
    userProfileFollowing: [],
    currentUserFollower: [],
    currentUserFollowing: [],
    loadingFav: false,
    reportVisible: false,
    blockVisible: false,
  })

  const {
    showAll,
    reviewVisible,
    priceRanges,
    lowPriceSign,
    highPriceSign,
    videoPlaying,
    showQRCode,
    showComment,
    teams,
    posts,
    loading,
    organizationData,
    questionReviews,
    questionReviewsModal,
    reviewStatus,
    questionsReviewVisible,
    teamTravel,
    targetPlayerLevel,
    targetTeamCompetition,
    loadingChat,
    allChannels,
    allUsers,
    loadingInterest,
    organizationUserData,
    loadingFollow,
    followVisible,
    followVisibleType,
    loadingRemove,
    userProfileFollower,
    userProfileFollowing,
    currentUserFollower,
    currentUserFollowing,
    loadingFav,
    reportVisible,
    blockVisible,
  } = state
  const [getUserLoader, setGetUserLoader] = useState(false)
  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  const handleCloseQRCode = () => {
    handleChange("showQRCode", false)
  }


  const _getTeamTravel = async () => {
    try {
      const token = localStorage.getItem("token")

      const res = await getTeamTravel(token)
      handleChange("teamTravel", res?.data?.results)
    } catch (error) {
    }
  }

  const _getTargetTeamCompetition = async () => {
    try {
      const token = localStorage.getItem("token")

      const res = await getTargetTeamCompetition(token)
      handleChange("targetTeamCompetition", res?.data?.results)
    } catch (error) {
    }
  }
  const _createChannel = async () => {
    try {
      handleChange("loadingChat", true)
      const payload = {
        users: [Number(id), user?.id]
      }
      const res = await createChannel(payload)
      handleChange("loadingChat", false)
      navigate(`/messages/${res?.data?.id}`)
    } catch (error) {
      handleChange("loadingChat", false)
      const errorText = Object.values(error?.response?.data)
      enqueueSnackbar(`Error: ${errorText[0]}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _showInterest = async () => {
    try {
      handleChange("loadingInterest", true)
      const payload = {
        organization_id: organizationData?.id
      }
      const res = await showInterest(payload)
      handleChange("loadingInterest", false)
      enqueueSnackbar("Interest successfully shown to the organization", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    } catch (error) {
      handleChange("loadingInterest", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _getTargetPlayerLevel = async () => {
    try {
      const token = localStorage.getItem("token")

      const res = await getTargetPlayerLevel(token)
      handleChange("targetPlayerLevel", res?.data?.results)
    } catch (error) {
    }
  }

  useEffect(() => {
    if (id && (!apiCallsMade || organizationUserData?.id != id)) {
      setApiCallsMade(true)
      // _getTargetTeamCompetition()
      getProfileData()
      _getAllPosts()
      _getAllSharedUsers()
      _getChannels()
      // _getTeamTravel()
      // _getTargetPlayerLevel()
    }
  }, [id])

  const getProfileData = async (noLoading) => {
    try {
      handleChange("loading", noLoading ? false : true)
      const res = await getUserProfile(id, token)
      handleChange("organizationUserData", res?.data)
      handleChange("organizationData", res?.data?.organization)
      handleChange("loading", false)
      handleChange("loadingFollow", "")
      const payload = `?user_id=${id}`
      const currentUserFollower = await getUserProfileFollower("", token)
      const currentUserFollowing = await getUserProfileFollowing("", token)
      const resFollower = await getUserProfileFollower(payload, token)
      const resFollowing = await getUserProfileFollowing(payload, token)
      getData(res?.data?.organization?.id)
      handleChange("lowPriceSign", "")
      handleChange("highPriceSign", "")
      handleChange("userProfileFollower", resFollower?.data)
      handleChange("userProfileFollowing", resFollowing?.data)
      handleChange("currentUserFollower", currentUserFollower?.data)
      handleChange("currentUserFollowing", currentUserFollowing?.data)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getAllPosts = async (payload, resetPage) => {
    try {
      // handleChange("posts", [])
      // handleChange("loading", true)
      const token = localStorage.getItem("token")
      const res = await getAllPosts(`?user__id=${id}`, token)
      handleChange("posts", res?.data?.results)
      // handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      // handleChange("loading", false)
    } catch (error) {
      // handleChange("loading", false)
      getError(error)
    }
  }
  const getData = async id => {
    try {
      // handleChange("loading", true)
      handleChange("questionReviewsModal", [])
      const res = await getOrganizationTeam(`?organization__id=${id}`, token)
      const resReview = await reviewsCatScore(`?organization_id=${id}`, token)
      const resReviewStatus = await checkReviewStatus(
        `?organization_id=${id}`,
        token
      )
      _getPriceRange()
      handleChange("teams", res?.data)
      handleChange("questionReviews", resReview?.data?.results)
      handleChange("questionReviewsModal", resReview?.data?.results)
      handleChange("reviewStatus", resReviewStatus?.data?.completed)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const getAllCoaches = () => {
    const list = []
    teams?.results?.forEach(team => {
      if ((team?.coaches?.user?.name || team?.coach_name) && !list?.includes(team?.coaches?.user?.name || team?.coach_name)) {
        list?.push({ name: team?.coaches?.user?.name || team?.coach_name, id: team?.coaches?.user?.id || '' })
      }
    })
    return list
  }
  const arrayList =
    organizationData?.hash_tags && Object.entries(organizationData?.hash_tags)
  const finalList = showAll
    ? arrayList
    : arrayList?.length === 1
      ? [arrayList?.[0]]
      : arrayList?.length > 1
        ? [arrayList?.[0], arrayList?.[1]]
        : []
  const _getPriceRange = async () => {
    try {
      // handleChange("loading", true)
      const res = await getPriceRange(token)
      if (res) {
        handleChange("priceRanges", res?.data)
      }
      // handleChange("loading", false)
    } catch (error) {
      // handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getAllSharedUsers = async () => {
    setGetUserLoader(true)
    try {
      const res = await getUsers("?limit=20&offset=20/", token)
      if (res) {
        handleChange("allUsers", res?.data?.results)
        setGetUserLoader(false)
      }
    } catch (error) {
      setGetUserLoader(false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getChannels = async () => {
    try {
      // handleChange("loading", true)
      const res = await getChannels()
      handleChange("allChannels", res?.data?.channels)
      // handleChange("loading", false)
    } catch (error) {
      // handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  useEffect(() => {
    if (priceRanges) {
      let lowPriceSign = "" // Initialize to empty string
      let highPriceSign = "" // Initialize to empty string

      // Loop through priceRanges to find low price sign
      priceRanges.forEach(item => {
        if (
          organizationData?.price_low >= item?.min_price &&
          organizationData?.price_low <= item?.max_price &&
          item?.range_sign
        ) {
          lowPriceSign = item.range_sign
        }
      })

      // Loop through priceRanges to find high price sign
      priceRanges.forEach(item => {
        if (
          organizationData?.price_high >= item?.min_price &&
          organizationData?.price_high <= item?.max_price &&
          item?.range_sign
        ) {
          highPriceSign = item.range_sign
        }
      })

      // Update the state with the found signs or "Out of range" if not found
      handleChange("lowPriceSign", lowPriceSign || "Out of range")
      handleChange("highPriceSign", highPriceSign || "Out of range")
    }
  }, [priceRanges, id])

  const _removeFollower = async f_id => {
    try {
      handleChange("loadingRemove", f_id)
      const token = localStorage.getItem("token")
      // if (user?.role === "Parent") {
      //   await removeFollower(
      //     `?user_id=${f_id}&player_id=${userData?.player?.id}`,
      //     token
      //   )
      // } else {
      await removeFollower(`?user_id=${f_id}`, token)
      // }
      getProfileData(true)
      handleChange("loadingRemove", "")
    } catch (error) {
      handleChange("loadingRemove", "")
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const _addFollow = async (u_id, type) => {
    try {
      const f_id = u_id || id
      handleChange("loadingFollow", f_id)
      const token = localStorage.getItem("token")
      if (type === "unfollow") {
        // if (user?.role === "Parent") {
        //   await removeFollow(
        //     `?user_id=${f_id}&player_id=${organizationUserData?.id}`,
        //     token
        //   )
        // } else {
        await removeFollow(`?user_id=${f_id}`, token)
        // }
      } else {
        await addFollow(`?user_id=${f_id}`, token)
      }
      getProfileData(true, u_id)
      // handleChange("loadingFollow", "")
    } catch (error) {
      handleChange("loadingFollow", "")
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }

  const handleOpenFollow = type => {
    handleChange("followVisible", true)
    handleChange("followVisibleType", type)
  }

  const handleCloseFollow = () => {
    handleChange("followVisible", false)
    handleChange("followVisibleType", "")
  }

  const handleCloseReport = () => {
    handleChange("reportVisible", false)
  }

  const handleCloseBlock = () => {
    handleChange("blockVisible", false)
  }


  const _addPlayerToFav = async () => {
    try {
      handleChange("loadingFav", true)
      const token = localStorage.getItem("token")
      if (organizationUserData?.is_favorite) {
        await removePlayerToFav(`?user_id=${id}`, token)
      } else {
        await addPlayerToFav(`?user_id=${id}`, token)
      }
      getProfileData(true)
      handleChange("loadingFav", false)
    } catch (error) {
      handleChange("loadingFav", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }


  return (
    <div className="">
      <HomeHeader dashboard />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="greyBG">
            <section className="container p-0 bg-transparent">
              <Grid container>
                <Grid
                  alignItems={"center"}
                  sx={{ mb: 4, mt: 4 }}
                  item
                  xs={12}
                  md={8}
                >
                  <Grid container alignItems={"center"}>
                    <Grid item>
                      <img
                        src={
                          organizationData?.logo
                            ? organizationData?.logo
                            : AvatarIcon
                        }
                        className={"profileIMG"}
                      />
                    </Grid>
                    <Grid item>
                      <p className={"nameOrganization"}>
                        {organizationData?.organization_name}
                        <img alt={organizationData?.circuit_affiliation?.name} src={organizationData?.circuit_affiliation?.image} className="circuit_affiliation_image_badge" />
                        <img alt={organizationData?.main_sport?.name} src={organizationData?.main_sport?.image} className="circuit_affiliation_image_badge1" />
                      </p>
                      <p
                        className="taglineOrganization"
                        style={{ marginLeft: 20 }}
                      >
                        {organizationData?.tag_line}
                      </p>
                      <div className="d-flex mt-2" style={{ marginLeft: 20 }}>
                        <div
                          className="mr-5 cursor"
                          onClick={() => handleOpenFollow("follower")}
                        >
                          <div className="w-label text-black">Followers</div>
                          <div className="w-value text-black">
                            {organizationUserData?.followers_count}
                          </div>
                        </div>
                        <div
                          className="cursor"
                          onClick={() => handleOpenFollow("following")}
                        >
                          <div className={"w-label text-center text-black"}>Following</div>
                          <div className="w-value text-black">
                            {organizationUserData?.following_count}
                          </div>
                        </div>
                      </div>
                      {user?.id != id && (
                        <div>
                          <AppButton
                            color={"#000"}
                            width={100}
                            height={35}
                            className={"ml-3 mt-2"}
                            fontWeight={"400"}
                            title={
                              <div
                                className="d-flex align-items-center"
                                style={{ marginLeft: -5 }}
                              >
                                <StarFill
                                  style={{ marginTop: -3 }}
                                  width={20}
                                />{" "}
                                <span className="ml-1">
                                  {organizationUserData?.is_following
                                    ? "Unfollow"
                                    : "Follow"}
                                </span>
                              </div>
                            }
                            onClick={() =>
                              _addFollow(
                                organizationUserData?.id,
                                organizationUserData?.is_following ? "unfollow" : "follow"
                              )
                            }
                            loading={!followVisible && loadingFollow}
                          />
                          <AppButton
                            width={100}
                            color={"#000"}
                            // borderColor={COLORS.white}
                            className={"ml-3 mt-2"}
                            height={35}
                            title={
                              <div
                                className="d-flex align-items-center"
                                style={{ marginLeft: -5 }}
                              >
                                {organizationUserData?.is_favorite ? (
                                  <UnFavWhite style={{ marginTop: -2 }} />
                                ) : (
                                  <FavWhite style={{ marginTop: -2 }} />
                                )}{" "}
                                <span
                                  className={
                                    organizationUserData?.is_favorite
                                      ? "ml-1 text-black"
                                      : "ml-1 text-black"
                                  }
                                >
                                  {organizationUserData?.is_favorite
                                    ? "Unfavorite"
                                    : "Favorite"}
                                </span>
                              </div>
                            }
                            fontWeight={"400"}
                            onClick={_addPlayerToFav}
                            loading={loadingFav}
                          />
                          <AppButton
                            width={120}
                            height={35}
                            className={"ml-3 mt-2"}
                            loading={loadingChat}
                            onClick={_createChannel}
                            title={
                              <div
                                className="d-flex align-items-center"
                                style={{ marginLeft: -5 }}
                              >
                                <MessageFill
                                  style={{ marginTop: -3 }}
                                  width={20}
                                />{" "}
                                <span className="ml-1">Message</span>
                              </div>
                            }
                            fontWeight={"400"}
                          />
                          {
                            isPlayer &&
                            <AppButton
                              width={120}
                              height={35}
                              className={"ml-3 mt-2"}
                              loading={loadingInterest}
                              onClick={_showInterest}
                              title={
                                <div
                                  className="d-flex align-items-center"
                                  style={{ marginLeft: -5 }}
                                >
                                  <span className="ml-1">Join our team</span>
                                </div>
                              }
                              fontWeight={"400"}
                            />
                          }
                        </div>
                      )}

                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction={"column"}
                  alignItems={"flex-end"}
                  sx={{ mb: 4, mt: 4 }}
                  item
                  xs={12}
                  md={4}
                >
                  {user?.id == id && (
                    <div className="d-flex align-items-center">
                      {organizationData?.qr_code && (
                        <Grid>
                          <AppButton
                            width={35}
                            height={35}
                            onClick={() => handleChange("showQRCode", true)}
                            borderRadius={35}
                            className={"min-width-remove mr-2 mb-3"}
                            title={
                              <QRcodeIcon style={{ marginTop: -3 }} width={20} />
                            }
                          />
                        </Grid>
                      )}
                      <AppButton
                        width={35}
                        height={35}
                        borderRadius={35}
                        onClick={() =>
                          navigate(`/organization-profile/${id}/edit`)
                        }
                        backgroundColor={COLORS.primary}
                        className={"min-width-remove mb-3"}
                        title={<Edit />}
                      />
                    </div>
                  )}
                  {organizationData?.live_stream && (
                    <AppButton
                      width={100}
                      height={35}
                      borderRadius={35}
                      onClick={() =>
                        window.open(organizationData?.live_stream, '_blank')
                      }
                      // color={COLORS.white}
                      // backgroundColor={COLORS.primary}
                      className={"min-width-remove mb-3"}
                      title={"Live stream"}
                    />
                  )}
                  <Grid
                    container
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                  >
                    {finalList.map((item, index) => (
                      <div key={index} container justifyContent={"flex-end"}>
                        <div className="hashtag">
                          {item[0] + " (" + item[1] + ")"}
                        </div>
                      </div>
                    ))}
                  </Grid>
                  {arrayList?.length > 2 && (
                    <div
                      onClick={() => handleChange("showAll", !showAll)}
                      className="seemore"
                    >
                      {showAll ? "See Less" : "See More"}
                    </div>
                  )}
                  {user?.id != id && (
                    <Grid container justifyContent={"flex-end"}>
                      {(isPlayer || isParent) && (
                        <AppButton
                          width={180}
                          height={35}
                          onClick={() => handleChange("reviewVisible", true)}
                          className={"mt-3"}
                          title={"Add a Tag"}
                          color={"#000000D9"}
                          backgroundColor={"#FFFFFF"}
                          borderColor={"#D9D9D9"}
                        />
                      )}
                      {(isPlayer || isParent) && (
                        <AppButton
                          width={200}
                          height={35}
                          onClick={() =>
                            handleChange("questionsReviewVisible", true)
                          }
                          className={"mt-3 ml-1"}
                          title={"Add a Review"}
                          color={"#000000D9"}
                          backgroundColor={"#FFFFFF"}
                          borderColor={"#D9D9D9"}
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </section>
            {user?.id != id && (
              <section className="container pb-3 p-0 mb-0 bg-transparent">
                <Grid container item xs={12} md={12} justifyContent={"flex-end"}>
                  <AppButton
                    onClick={() => handleChange("reportVisible", true)}
                    title={
                      <div>
                        <ReportIcon
                          width={20}
                          height={15}
                          style={{ marginTop: -2, marginLeft: -5 }}
                        />{" "}
                        Report
                      </div>
                    }
                    width={120}
                    color={"#fff"}
                    height={40}
                    backgroundColor={"#0059C1"}
                  />
                  <AppButton
                    onClick={() => handleChange("blockVisible", true)}
                    title={
                      <div>
                        <ReportIcon
                          width={20}
                          height={15}
                          style={{ marginTop: -2, marginLeft: -5 }}
                        />{" "}
                        Block
                      </div>
                    }
                    width={120}
                    className={'ml-2'}
                    color={"#fff"}
                    height={40}
                    backgroundColor={"#0059C1"}
                  />
                </Grid>
              </section>
            )}
          </div>
          
          <section className="container p-0  bg-transparent">
            <Grid
              container
              item
              xs={12}
              className="mt-3 mb-2"
            >
              {organizationData?.display_tiktok && (
                <AppButton
                  width={35}
                  height={35}
                  onClick={() =>
                    gotoLink(organizationData?.tiktok_username)
                  }
                  borderRadius={15}
                  className={"min-width-remove"}
                  backgroundColor={'transparent'}
                  borderColor={"#D9D9D9"}
                  title={
                    <Tiktok style={{ marginTop: -3 }} width={20} />
                  }
                />
              )}
              {organizationData?.display_facebook && (
                <AppButton
                  width={35}
                  height={35}
                  backgroundColor={'transparent'}
                  borderColor={"#D9D9D9"}
                  onClick={() =>
                    gotoLink(organizationData?.facebook_username)
                  }
                  className={"min-width-remove ml-2 mr-2"}
                  borderRadius={15}
                  title={
                    <Facebook style={{ marginTop: -3 }} width={20} />
                  }
                />
              )}
              {organizationData?.display_twitter && (
                <AppButton
                  width={35}
                  height={35}
                  backgroundColor={'transparent'}
                  borderColor={"#D9D9D9"}
                  onClick={() =>
                    gotoLink(organizationData?.twitter_username)
                  }
                  className={"min-width-remove mr-2"}
                  borderRadius={15}
                  title={
                    <Twitter style={{ marginTop: -3 }} width={20} />
                  }
                />
              )}
              {organizationData?.display_instagram && (
                <AppButton
                  width={35}
                  height={35}
                  backgroundColor={'transparent'}
                  borderColor={"#D9D9D9"}
                  onClick={() =>
                    gotoLink(organizationData?.instagram_username)
                  }
                  borderRadius={15}
                  className={"min-width-remove mr-2"}
                  title={<Insta style={{ marginTop: -3 }} width={20} />}
                />
              )}
              {organizationData?.display_youtube && (
                <AppButton
                  width={35}
                  height={35}
                  backgroundColor={'transparent'}
                  borderColor={"#D9D9D9"}
                  onClick={() =>
                    gotoLink(organizationData?.youtube_username)
                  }
                  borderRadius={15}
                  className={"min-width-remove mr-2"}
                  title={
                    <Youtube style={{ marginTop: -3 }} width={20} />
                  }
                />
              )}
              {organizationData?.display_threads && (
                <AppButton
                  width={35}
                  height={35}
                  backgroundColor={'transparent'}
                  borderColor={"#D9D9D9"}
                  onClick={() =>
                    gotoLink(organizationData?.threads_username)
                  }
                  borderRadius={15}
                  className={"min-width-remove"}
                  title={
                    <Threads style={{ marginTop: -3 }} width={20} />
                  }
                />
              )}
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6} style={{ padding: "10px 20px" }}>
                <p className="taglineOrganization">Bio</p>
                <span className="bioOrganization">
                  <SeeMoreText text={organizationData?.bio} maxLength={275} />
                </span>
              </Grid>
              <Grid item xs={12} md={6}>
                <p className="taglineOrganization1">Reviews ({questionReviews?.[0]?.max_review_count ?? 0})</p>
                <Grid container className="reviewBox">
                  {questionReviews?.map((item, index) => (
                    <Grid
                      key={index}
                      direction={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      item
                      xs={6}
                      md={4}
                      lg={2}
                      style={{
                        padding: "10px 20px",
                        border:
                          index === questionReviews?.length - 1 || index === 0
                            ? "none"
                            : "1px solid rgba(0, 0, 0, 0.06)",
                        textAlign: "center"
                      }}
                    >
                      <img
                        src={
                          item[item?.display_icon]
                            ? item[item?.display_icon]
                            : "https://static-00.iconduck.com/assets.00/no-image-icon-512x512-lfoanl0w.png"
                        }
                        className="reviewImage"
                      />
                      <p
                        className="font-bold text-black "
                        style={{
                          fontSize: 10,
                          marginLeft: index === 0 ? -5 : 0
                        }}
                      >
                        {item?.name}
                      </p>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </section>
          <div className="border-bottom">
            <section className="container p-0 pl-3 bg-transparent">
              <Grid container className="">
                <Grid item xs={6} md={3} className="border border-top-none p-3">
                  <Grid container justifyContent={"space-between"}>
                    <Grid item xs={12}>
                      <span className="font-bold mb-3 text-black">
                        Team Travel
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <span className="text-black">
                        {organizationData?.team_travel
                          ?.map(
                            v => v?.name
                          )
                          .join(", ") || ""}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item xs={12}>
                      <span className="font-bold mb-3 text-black">
                        Team Competition
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <span className="text-black">
                        {organizationData?.target_team_competition
                          ?.map(
                            v => v?.name
                          )
                          .join(", ") || ""}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"space-between"}>
                    <Grid item xs={12}>
                      <span className="font-bold mb-3 text-black">
                        Team Player Level
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <span className="text-black">
                        {organizationData?.target_player_level
                          ?.map(
                            v => v?.name
                          )
                          .join(", ") || ""}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2.25}
                  className="border border-top-none p-3"
                >
                  <div className="font-bold text-black">
                    Program Directors
                  </div>
                  <div className="text-black">
                    {organizationData?.program_director_name ? (
                      organizationData?.program_director_name
                    ) : (
                      <p className="text-left ml-3">N / A</p>
                    )}
                  </div>
                  {/* <div className="font-bold mt-3 text-black">
                    Circuit Affiliation
                  </div>
                  <div className="text-black d-flex">
                    <img alt={organizationData?.circuit_affiliation?.name} src={organizationData?.circuit_affiliation?.image} className="circuit_affiliation_image" />
                    {organizationData?.circuit_affiliation?.name}
                  </div> */}
                  <div className="font-bold mt-3 text-black">
                    Circuit participation
                  </div>
                  {organizationData?.circuit_participation?.map((circuit_participation, index) => (
                    <div key={index} className="text-black">
                      {circuit_participation?.name}
                    </div>
                  ))}
                  <div className="font-bold mt-3 text-black">
                    Circuit Affiliation Rank (Current Highest Team)
                  </div>
                  <div className="text-black">
                    {organizationData?.circuit_rank ? (
                      organizationData?.circuit_rank
                    ) : (
                      <p className="text-left ml-3">N / A</p>
                    )}
                  </div>
                  {/* <div className="font-bold mt-3 text-black">
                    Live stream
                  </div>
                  <div className="text-black">
                    {organizationData?.live_stream ? (
                      organizationData?.live_stream
                    ) : (
                      <p className="text-left ml-3">N / A</p>
                    )}
                  </div> */}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2.25}
                  className="border border-top-none p-3"
                >
                  <div className="font-bold mb-3 text-black">Coaches</div>
                  {getAllCoaches()?.map((coach, index) => (
                    <div key={index} onClick={() => coach?.id ? navigate(`/coach-profile/${coach?.id}`) : console.log()} className={coach?.id ? "text-black cursor hyperLink" : "text-black"}>
                      {coach?.name}
                    </div>
                  ))}
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={2.25}
                  className="border border-top-none p-3"
                >
                  <div className="font-bold text-black">Price</div>
                  <div className="text-black">
                    {organizationData?.price_low || organizationData?.price_high
                      ? `${lowPriceSign} - ${highPriceSign}`
                      : "N/A"}
                  </div>
                </Grid>
                <Grid item xs={6} md={2} className="border border-top-none p-3">
                  <div className="font-bold text-black">Phone Number</div>
                  <div className="text-black">
                    {organizationUserData?.phone_number}
                  </div>
                  <div className="font-bold mt-3 text-black">Email</div>
                  <div className="text-black word-wrap">
                    {organizationUserData?.email}
                  </div>
                </Grid>
              </Grid>
            </section>
          </div>
          <section className="container pb-3 mb-0 bg-transparent">
            <Grid className="mb-3 border round-1">
              <Grid
                className="mt-2 mb-2 p-2"
                alignItems={"center"}
                container
                justifyContent={"space-between"}
              >
                <div className="font-20 font-bold text-black">
                  {organizationData?.organization_name} Team
                </div>
              </Grid>
              <OrganizationTeamTable
                teams={teams}
                noActions
                loading={loading}
                getData={getData}
                organizationData={organizationData}
              />
            </Grid>
          </section>

          {/* User Posts Section */}
          <section className="container pb-3 mb-0 bg-transparent">
            <Grid item xs={6} md={2}>
              <div className="text-black font-24 font-500">Activities</div>
              <Divider className="mt-4" />
              {posts?.map((post, index) => (
                <Post
                  key={index}
                  state={state}
                  showComment={showComment}
                  handleChange={handleChange}
                  post={post}
                  getData={getData}
                  videoPlaying={videoPlaying}
                  allUsers={allUsers}
                  getUserLoader={getUserLoader}
                  allChannels={allChannels}
                  index={index}
                />
              ))}
            </Grid>
          </section>
        </>
      )}
      <QRCode visible={showQRCode} type={'organization'} organizationData={organizationData} handleClose={handleCloseQRCode} />
      <AddReview
        visible={reviewVisible}
        getProfileData={getProfileData}
        organizationData={organizationData}
        handleClose={() => handleChange("reviewVisible", false)}
      />
      <AddQuestionsReview
        visible={questionsReviewVisible}
        questionReviews={questionReviewsModal}
        getData={getData}
        organizationData={organizationData}
        handleClose={() => handleChange("questionsReviewVisible", false)}
      />
      <FollowUser
        visible={followVisible}
        _addFollow={_addFollow}
        loadingRemove={loadingRemove}
        _removeFollower={_removeFollower}
        loading={loadingFollow}
        userProfileFollower={userProfileFollower}
        userProfileFollowing={userProfileFollowing}
        currentUserFollower={currentUserFollower}
        currentUserFollowing={currentUserFollowing}
        followVisibleType={followVisibleType}
        handleClose={handleCloseFollow}
      />
       <ReportUser
        visible={reportVisible}
        handleClose={handleCloseReport}
        userId={id}
      />
      <BlockUser
        visible={blockVisible}
        handleClose={handleCloseBlock}
        userId={id}
      />
    </div>
  )
}
