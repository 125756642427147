import React, { useContext, useEffect, useState } from "react"
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput"
import { styled } from "@mui/system"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import { AppButton, AppInput, Label } from ".."
import {
  Box,
  Grid,
  Modal,
  Popover,
  Typography,
  TextField,
  Autocomplete as TextAutocomplete,
  colors
} from "@mui/material"
import { COLORS } from "../../constants"
import { ReactComponent as Cross } from "../../assets/svg/Cross.svg"
import { ReactComponent as ExclamationCircle } from "../../assets/svg/ExclamationCircle.svg"
import {
  createOrganizationTeamStat,
  getOrganizationTeam,
  getUserProfile,
  updatePlayerTeamStat,
  updateBaseballStats,
  addBaseballStats
} from "../../api/auth"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import AppContext from "../../Context"
import moment from "moment"
import QuantityInput from "../AppInput/NumberInput"
import { basemans, outfielders } from "../../utils/mixed"

function BaseBallStats({
  organization,
  visible,
  handleClose,
  teamID,
  playerId,
  playerStatOption,
  statData
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [dropDownText, setDropDownText] = useState("")
  const [dateOfGameError, setdateOfGameError] = useState(false)
  const [teamPlayedError, setTeamPlayedError] = useState(false)
  const { organizationData, user } = useContext(AppContext)
  const token = localStorage.getItem("token")

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: organization ? 350 : "80%",
    borderRadius: 6,
    maxHeight: "90%",
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4
  }
  const [anchorEl, setAnchorEl] = useState(null)
  const [state, setState] = useState({
    date_of_game: "",
    opponent_team: "",
    opponent_team_custom: "",
    result: "",
    points_scored: "",
    games: 0,
    assists: 0,
    hits: 0,
    at_bats: 0,
    wins: 0,
    losses: 0,
    home_runs: 0,
    runs_batted_in: 0,
    walks: 0,
    on_base_percentage: 0,
    hit_by_pitch: 0,
    sacrifice_flies: 0,
    bases: 0,
    earned_runs: 0,
    innings_pitched: 0,
    strikeouts: 0,
    stolen_bases_against: 0,
    saves: 0,
    passed_balls: 0,
    caught_stealing: 0,
    loading: false,
    teams: [],
    userProfileData: null
  })
  const {
    date_of_game,
    opponent_team,
    opponent_team_custom,
    result,
    points_scored,
    games,
    assists,
    hits,
    at_bats,
    losses,
    wins,
    home_runs,
    runs_batted_in,
    walks,
    on_base_percentage,
    hit_by_pitch,
    bases,
    sacrifice_flies,
    earned_runs,
    innings_pitched,
    strikeouts,
    loading,
    teams,
    caught_stealing,
    stolen_bases_against,
    passed_balls,
    saves,
    putouts,
    errors,
    double_plays,
    outfield_assists,
    userProfileData
  } = state

  const points_scored_calculated =
    Number(hit_by_pitch || 0) * 2 +
    Number(bases || 0) * 3 +
    Number(innings_pitched || 0) * 1

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    if (visible) {
      getData()
      _getProfileData()
    }
  }, [visible])

  const _getProfileData = async () => {
    try {
      handleChange("loadingData", true)
      const token = localStorage.getItem("token")
      const res = await getUserProfile(user?.role === "Parent" ? playerId?.userID : user?.id, token)
      handleChange("userProfileData", res?.data)
      handleChange("loadingData", false)
    } catch (error) {
      handleChange("loadingData", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  useEffect(() => {
    if (statData && Object.keys(statData).length !== 0) {
      handleChange("date_of_game", statData?.date_of_game)
      // getData(`?`)
      if (teams?.length && teams?.some((e) => e?.id == statData?.opponent_team)) {
        setDropDownText(teams?.find((e) => e?.id == statData?.opponent_team)?.team_name)
      }
      handleChange("opponent_team", statData?.opponent_team)
      handleChange("opponent_team_custom", statData?.opponent_team_custom)
      handleChange("points_scored_calculated", statData?.points_scored_calculated)
      handleChange("games", statData?.games)
      handleChange("assists", statData?.assists)
      handleChange("hits", statData?.hits)
      handleChange("at_bats", statData?.at_bats)
      handleChange("home_runs", statData?.home_runs)
      handleChange("runs_batted_in", statData?.runs_batted_in)
      handleChange("walks", statData?.walks)
      handleChange("on_base_percentage", statData?.on_base_percentage)
      handleChange("hit_by_pitch", statData?.hit_by_pitch)
      handleChange("sacrifice_flies", statData?.sacrifice_flies)
      handleChange("bases", statData?.bases)
      handleChange("earned_runs", statData?.earned_runs)
      handleChange("innings_pitched", statData?.innings_pitched)
      handleChange("strikeouts", statData?.strikeouts)
      handleChange("wins", statData?.wins)
      handleChange("losses", statData?.losses)
      handleChange("stolen_bases_against", statData?.stolen_bases_against)
      handleChange("saves", statData?.saves)
      handleChange("passed_balls", statData?.passed_balls)
      handleChange("putouts", statData?.putouts)
      handleChange("errors", statData?.errors)
      handleChange("double_plays", statData?.double_plays)
      handleChange("outfield_assists", statData?.outfield_assists)
      handleChange("caught_stealing", statData?.caught_stealing)
    } else {
      handleChange("date_of_game", "")
      handleChange("opponent_team", "")
      handleChange("result", "")
      handleChange("opponent_team_custom", "")
      handleChange("games", 0)
      handleChange("assists", 0)
      handleChange("hits", 0)
      handleChange("at_bats", 0)
      handleChange("wins", 0)
      handleChange("losses", 0)
      handleChange("home_runs", 0)
      handleChange("runs_batted_in", 0)
      handleChange("walks", 0)
      handleChange("on_base_percentage", 0)
      handleChange("points_scored", 0)
      handleChange("sacrifice_flies", 0)
      handleChange("hit_by_pitch", 0)
      handleChange("bases", 0)
      handleChange("earned_runs", 0)
      handleChange("innings_pitched", 0)
      handleChange("stolen_bases_against", 0)
      handleChange("saves", 0)
      handleChange("caught_stealing", 0)
      handleChange("passed_balls", 0)
      handleChange("putouts", 0)
      handleChange("errors", 0)
      handleChange("double_plays", 0)
      handleChange("outfield_assists", 0)
      setDropDownText("")
      handleChange("strikeouts", 0)
    }
  }, [statData, visible, teams])

  const getData = async (payload = '') => {
    try {
      handleChange("loading", true)
      const res = await getOrganizationTeam(payload, token)
      handleChange("teams", res?.data?.results)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopup = () => {
    setAnchorEl(null)
  }

  const handleSubmit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (opponent_team === "" && opponent_team_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        const assistsValue = assists || 0
        const payload = {
          date_of_game,
          opponent_team,
          opponent_team_custom,
          points_scored: points_scored_calculated,
          assists: assistsValue,
          games,
          hits,
          at_bats,
          wins,
          losses,
          home_runs,
          runs_batted_in,
          walks,
          on_base_percentage,
          hit_by_pitch,
          sacrifice_flies,
          bases,
          earned_runs,
          innings_pitched,
          strikeouts,
          saves,
          stolen_bases_against,
          passed_balls,
          putouts,
          errors,
          double_plays,
          outfield_assists,
          caught_stealing
        }
        if (user?.role === "Parent") {
          payload.player_id = playerId?.id // Add player_id if user's role is parent
        }
        await addBaseballStats(payload, token)
        handleChange("loading", false)
        handleChange("date_of_game", "")
        handleChange("opponent_team", "")
        handleChange("result", "")
        handleChange("opponent_team_custom", "")
        handleChange("assists", 0)
        handleChange("games", 0)
        handleChange("hits", 0)
        handleChange("at_bats", 0)
        handleChange("wins", 0)
        handleChange("losses", 0)
        handleChange("home_runs", 0)
        handleChange("runs_batted_in", 0)
        handleChange("walks", 0)
        handleChange("on_base_percentage", 0)
        handleChange("points_scored", 0)
        handleChange("sacrifice_flies", 0)
        handleChange("hit_by_pitch", 0)
        handleChange("bases", 0)
        handleChange("earned_runs", 0)
        handleChange("innings_pitched", 0)
        handleChange("stolen_bases_against", 0)
        handleChange("saves", 0)
        handleChange("passed_balls", 0)
        handleChange("caught_stealing", 0)
        handleChange("putouts", 0)
        handleChange("errors", 0)
        handleChange("double_plays", 0)
        handleChange("outfield_assists", 0)
        setDropDownText("")
        handleChange("strikeouts", 0)
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleEdit = async () => {
    try {
      if (date_of_game === "") {
        alert("Please enter date of game")
      }
      if (opponent_team === "" && opponent_team_custom === "") {
        alert("Please enter team played")
      } else {
        handleChange("loading", true)
        const assistsValue = assists || 0

        if (organization) {
          const payload = {
            date_of_game,
            opponent_team,
            result,
            organization: organizationData?.id,
            team: teamID
          }
          await createOrganizationTeamStat(payload, token)
        } else {
          const payload = {
            date_of_game,
            opponent_team,
            opponent_team_custom,
            points_scored: points_scored_calculated,
            assists: assistsValue,
            games,
            hits,
            at_bats,
            wins,
            losses,
            home_runs,
            runs_batted_in,
            walks,
            on_base_percentage,
            hit_by_pitch,
            sacrifice_flies,
            bases,
            earned_runs,
            innings_pitched,
            strikeouts,
            stolen_bases_against,
            saves,
            passed_balls,
            putouts,
            errors,
            double_plays,
            outfield_assists,
            caught_stealing
          }
          if (user?.role === "Parent") {
            payload.player_id = playerId?.id // Add player_id if user's role is parent
          }
          await updateBaseballStats(statData?.id, payload, token)
        }
        handleChange("loading", false)
        handleChange("date_of_game", "")
        handleChange("opponent_team", "")
        handleChange("result", "")
        handleChange("opponent_team_custom", "")
        handleChange("assists", 0)
        handleChange("games", 0)
        handleChange("hits", 0)
        handleChange("at_bats", 0)
        handleChange("wins", 0)
        handleChange("losses", 0)
        handleChange("home_runs", 0)
        handleChange("runs_batted_in", 0)
        handleChange("walks", 0)
        handleChange("on_base_percentage", 0)
        handleChange("points_scored", 0)
        handleChange("sacrifice_flies", 0)
        handleChange("hit_by_pitch", 0)
        handleChange("bases", 0)
        handleChange("earned_runs", 0)
        handleChange("innings_pitched", 0)
        handleChange("stolen_bases_against", 0)
        handleChange("saves", 0)
        handleChange("passed_balls", 0)
        handleChange("putouts", 0)
        handleChange("errors", 0)
        handleChange("double_plays", 0)
        handleChange("outfield_assists", 0)
        handleChange("caught_stealing", 0)
        setDropDownText("")
        handleChange("strikeouts", 0)
        handleClose(true)
        enqueueSnackbar(`Stats has been added`, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          }
        })
      }
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  const positionName = userProfileData?.player?.first_position_play?.name
  return (
    <Modal
      open={visible}
      onClose={() => {
        setTeamPlayedError(true)
        setdateOfGameError(true)
        handleClose(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 1000 }}
    >
      <Box sx={style} className="postBox">
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          className="mb-3"
        >
          <div className="font-24 font-bold greyColor">Add Stats</div>
          <AppButton
            width={35}
            height={35}
            borderRadius={35}
            className={"min-width-remove"}
            backgroundColor={COLORS.white}
            borderColor={"#D9D9D9"}
            title={<Cross />}
            onClick={() => handleClose(false)}
          />
        </Grid>

        <Grid container spacing={3}>
          {user?.role == "Parent" && (
            <Grid item container xs={12} md={12} spacing={3}>
              <Grid item xs={12} md={4}>
                <AppInput
                  label={"Adding stats for"}
                  height={40}
                  // select
                  onChange={handleChange}
                  value={playerId?.name}
                  name={"playerId"}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <AppInput
              label={"Date of game"}
              max={moment().format("YYYY-MM-DD")}
              type={"date"}
              height={40}
              required={true}
              onChange={handleChange}
              value={date_of_game}
              name={"date_of_game"}
            />
            {dateOfGameError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text={"Team Played"} fontNormal />
            <TextAutocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              value={
                opponent_team_custom === "" ? dropDownText : opponent_team_custom
              }
              onChange={(event, newValue) => {
                handleChange("opponent_team_custom", "")
                handleChange(
                  "opponent_team",
                  teams[event?.target?.dataset?.optionIndex]?.id
                )
                setDropDownText(newValue)
              }}
              inputValue={
                opponent_team_custom === "" ? dropDownText : opponent_team_custom
              }
              onInputChange={(event, newInputValue) => {
                handleChange("opponent_team_custom", newInputValue)
                handleChange("opponent_team", "")
              }}
              options={teams?.map(option => option.team_name)}
              // getOptionLabel={(option) => option.team_name}
              label={false}
              renderInput={params => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9",
                      borderWidth: 1
                    },
                    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#D9D9D9"
                    }
                    // Add your desired z-index value here
                  }}
                  label=""
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    ...params.InputProps,
                    type: "search"
                  }}
                />
              )}
            />
            {teamPlayedError === true ? (
              <Label text={"required"} fontNormal color={COLORS.orange} />
            ) : (
              ""
            )}
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Batting Average"}
              onChange={handleChange}
              value={games}
              name={"games"}
            />
          </Grid> */}
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Hits"}
              onChange={handleChange}
              value={hits}
              name={"hits"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"At Bats"}
              onChange={handleChange}
              value={at_bats}
              name={"at_bats"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Home Runs"}
              onChange={handleChange}
              value={home_runs}
              name={"home_runs"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Runs Batted In"}
              onChange={handleChange}
              value={runs_batted_in}
              name={"runs_batted_in"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"On Base Percentage"}
              onChange={handleChange}
              value={on_base_percentage}
              name={"on_base_percentage"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Walks"}
              onChange={handleChange}
              value={walks}
              name={"walks"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Hit By Pitch"}
              onChange={handleChange}
              value={hit_by_pitch}
              name={"hit_by_pitch"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Sacrifice Flies"}
              onChange={handleChange}
              value={sacrifice_flies}
              name={"sacrifice_flies"}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <QuantityInput
              label={"Total Bases"}
              onChange={handleChange}
              value={bases}
              name={"bases"}
            />
          </Grid>
          {
            positionName === "Pitcher" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Earned Runs"}
                  onChange={handleChange}
                  value={earned_runs}
                  name={"earned_runs"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Innings Pitched"}
                  onChange={handleChange}
                  value={innings_pitched}
                  name={"innings_pitched"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Strikeouts"}
                  onChange={handleChange}
                  value={strikeouts}
                  name={"strikeouts"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Wins"}
                  onChange={handleChange}
                  value={wins}
                  name={"wins"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Losses"}
                  onChange={handleChange}
                  value={losses}
                  name={"losses"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"saves"}
                  onChange={handleChange}
                  value={saves}
                  name={"saves"}
                />
              </Grid>

            </>
          }
          {
            positionName === "Catcher" &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Caught Stealing"}
                  onChange={handleChange}
                  value={caught_stealing}
                  name={"caught_stealing"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Stolen Bases Against"}
                  onChange={handleChange}
                  value={stolen_bases_against}
                  name={"stolen_bases_against"}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Passed Balls"}
                  onChange={handleChange}
                  value={passed_balls}
                  name={"passed_balls"}
                />
              </Grid>
            </>
          }
          {
            (outfielders.includes(positionName) || basemans.includes(positionName)) &&
            <>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"putouts"}
                  onChange={handleChange}
                  value={putouts}
                  name={"putouts"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Assists"}
                  onChange={handleChange}
                  value={assists}
                  name={"assists"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <QuantityInput
                  label={"Errors"}
                  onChange={handleChange}
                  value={errors}
                  name={"errors"}
                />
              </Grid>
              {
                basemans.includes(positionName) &&
                <Grid item xs={12} md={4}>
                  <QuantityInput
                    label={"Double Plays"}
                    onChange={handleChange}
                    value={double_plays}
                    name={"double_plays"}
                  />
                </Grid>
              }
              {
                outfielders.includes(positionName) &&
                <Grid item xs={12} md={4}>
                  <QuantityInput
                    label={"Outfield Assists"}
                    onChange={handleChange}
                    value={outfield_assists}
                    name={"outfield_assists"}
                  />
                </Grid>
              }
            </>
          }
        </Grid>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopup}
          classes={{ paper: "comfirm-paper" }}
          sx={{ width: 350 }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <Grid className="p-4">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <ExclamationCircle className="mt-2" />
              </Grid>
              <Grid item xs={10}>
                <Typography id="modal-modal-description">
                  Are you sure you want to add this stats to your profile. This
                  cannot be changed later.
                </Typography>
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <AppButton
                title={"Yes"}
                height={40}
                onClick={() => {
                  handleClosePopup()
                  handleClose(false)
                }}
                width={"100%"}
                backgroundColor={COLORS.primary}
                className={"mb-2"}
                color={COLORS.white}
              />
              <AppButton
                title={"Cancel"}
                height={40}
                onClick={handleClosePopup}
                width={"100%"}
                backgroundColor={"#fff"}
                className={"mb-2"}
                borderColor={"#D9D9D9"}
              />
            </Grid>
          </Grid>
        </Popover>
        <Grid container justifyContent={"flex-end"}>
          <AppButton
            title={"Save"}
            backgroundColor={COLORS.primary}
            color={COLORS.white}
            width={300}
            height={40}
            className={"mt-5"}
            loading={loading}
            // disabled={disabled}
            onClick={statData && Object.keys(statData).length !== 0 ? handleEdit : handleSubmit}
          />
        </Grid>
      </Box>
    </Modal>
  )
}

export default BaseBallStats
