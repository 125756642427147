// @ts-nocheck
/* eslint-disable no-empty-pattern */
import React, { useContext, useState } from "react"
import { HomeHeader, AppButton, AddStats, Loader, Post, VolleyBallStats, BaseBallStats } from "../../components"
import { Grid, Divider } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../Context"
import AvatarIcon from "../../assets/images/avatar.png"
import {
  checkCoachReviewStatus,
  createChannel,
  declineLink,
  getAllPosts,
  getChannels,
  getOrganizationProfile,
  getUserProfile,
  reviewsCoachCatScore,
  searchUserProfile,
  searchUserProfileOld
} from "../../api/auth"
import SeeMoreText from "../../components/Text/SeeMoreText"
import { useSnackbar } from "notistack"
import { getError } from "../../utils/getError"
import { useEffect } from "react"
import { ReactComponent as Edit } from "../../assets/svg/Edit.svg"
import { COLORS } from "../../constants"
import { ReactComponent as MessageFill } from "../../assets/svg/MessageFill.svg"
import AddIcon from "@mui/icons-material/Add"
import LinkProfile from "../../components/GeneralModal/LinkPlayer"
import { getUsers } from "../../api/admin"
import DeleteIcon from "@mui/icons-material/Delete"
import FootBallStats from "../../components/Stats/FootBallStats"
import SoccerStats from "../../components/Stats/SoccerStats"

export default function LinkedPLayers({ }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const token = localStorage.getItem("token")
  const [getUserLoader, setGetUserLoader] = useState(false)
  const { user, _getProfile, isPlayer, isParent } = useContext(AppContext)
  const [state, setState] = useState({
    showAll: false,
    reviewVisible: false,
    statsVisible: false,
    teams: [],
    loading: false,
    userData: null,
    questionReviews: [],
    allUsers: [],
    allChannels: [],
    videoPlaying: "",
    showComment: "",
    posts: [],
    reviewStatus: false,
    questionsReviewVisible: false,
    loadingChat: false,
    organizationData: null,
    followVisible: false,
    loadingRemove: "",
    loadingFollow: "",
    allPlayers: [],
    linkedPlayers: [],
    playerId: {},
    linkVisible: false
  })

  const {
    showAll,
    teams,
    loading,
    userData,
    videoPlaying,
    showComment,
    posts,
    allUsers,
    allChannels,
    questionReviews,
    reviewStatus,
    questionsReviewVisible,
    loadingChat,
    followVisible,
    loadingRemove,
    loadingFollow,
    statsVisible,
    allPlayers,
    linkedPlayers,
    playerId,
    linkVisible
  } = state

  const handleChange = (key, value) => {
    setState(pre => ({ ...pre, [key]: value }))
  }

  useEffect(() => {
    _getProfile()
    // getAllPlayers()
    getLinkedPlayers(id)
    if (id) {
      getProfileData()
      _getAllPosts()
      _getAllSharedUsers()
      _getChannels()
    }
  }, [id, linkVisible, showAll])

  // const getAllPlayers = async () => {
  //   try {
  //     handleChange("loading", true)
  //     const res = await searchUserProfile(
  //       "?limit=1000&offset=20&role=Player",
  //       token
  //     )
  //     handleChange("allPlayers", res?.data?.results)
  //     handleChange("loading", false)
  //   } catch (error) {
  //     handleChange("loading", false)
  //     enqueueSnackbar(getError(error), {
  //       variant: "error",
  //       anchorOrigin: {
  //         vertical: "bottom",
  //         horizontal: "right"
  //       }
  //     })
  //   }
  // }
  const getLinkedPlayers = async () => {
    try {
      handleChange("loading", true)
      // const res = await searchUserProfile(`linked_relationships/`, token)
      let endpoint = `linked_relationships/`
      if (id) {
        endpoint += `?user_id=${id}`
      }
      const res = await searchUserProfileOld(endpoint, token)
      handleChange("linkedPlayers", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getAllPosts = async (payload, resetPage) => {
    try {
      // handleChange("posts", [])
      handleChange("loading", true)
      const token = localStorage.getItem("token")
      const res = await getAllPosts(`?user__id=${id}`, token)
      handleChange("posts", res?.data?.results)
      // handleChange("filteredList", res?.data?.results)
      handleChange("count", res?.data?.count)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      getError(error)
    }
  }
  const getProfileData = async () => {
    try {
      handleChange("loading", true)
      const res = await getUserProfile(id, token)
      handleChange("userData", res?.data)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }

  const _createChannel = async () => {
    try {
      handleChange("loadingChat", true)
      const payload = {
        users: [Number(id), user?.id]
      }
      const res = await createChannel(payload)
      handleChange("loadingChat", false)
      navigate(`/messages/${res?.data?.id}`)
    } catch (error) {
      handleChange("loadingChat", false)
      const errorText = Object.values(error?.response?.data)
      enqueueSnackbar(`Error: ${errorText[0]}`, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const handleOpenFollow = type => {
    handleChange("followVisible", true)
    handleChange("followVisibleType", type)
  }

  const handleCloseLink = () => {
    handleChange("linkVisible", false)
  }

  const handleOpenLink = type => {
    handleChange("linkVisible", true)
  }

  const handleCloseFollow = () => {
    handleChange("linkVisible", false)
    handleChange("followVisibleType", "")
  }

  const handleClose = getTeam => {
    handleChange("statsVisible", false)
    handleChange("teamID", "")
    if (getTeam) {
      // getData(0)
    }
  }
  const _getAllSharedUsers = async () => {
    setGetUserLoader(true)
    try {
      const res = await getUsers("?limit=20&offset=20/", token)
      if (res) {
        handleChange("allUsers", res?.data?.results)
        setGetUserLoader(false)
      }
    } catch (error) {
      setGetUserLoader(false)
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
    }
  }
  const _getChannels = async () => {
    try {
      handleChange("loading", true)
      const res = await getChannels()
      handleChange("allChannels", res?.data?.channels)
      handleChange("loading", false)
    } catch (error) {
      handleChange("loading", false)
      const errorText = Object.values(error?.response?.data)
      alert(`Error: ${errorText[0]}`)
    }
  }
  const declineLinkAction = async linkId => {
    try {
      const payload = {
        link_id: linkId
      }
      const res = await declineLink(payload, token)
      enqueueSnackbar(`Linking request deleted`, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleChange("showAll", !showAll)
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        }
      })
      handleChange("showAll", true)
    }
  }
  const isOwnProfile = id == user?.id

  return (
    <div>
      <HomeHeader dashboard />
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ paddingTop: "3rem" }}>
            <section className="container p-0 width60 bg-transparent">
              <div>
                <div
                  className="flex-between"
                  style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}
                >
                  <div
                    className="font-bold font-24"
                    style={{ color: "rgba(0, 0, 0, 0.85)" }}
                  >
                    Linked Players
                  </div>
                </div>
                {!isOwnProfile
                  ? linkedPlayers
                    .filter(item => item?.is_confirmed)
                    ?.map((member, index) => (
                      <div className="parentGreyBox" key={index}>
                        <div
                          className="flex-between"
                          style={{ width: "100%" }}
                        >
                          <div className="d-flex">
                            <img
                              src={member?.player?.picture || AvatarIcon}
                              className={"c-pointer memberprofile"}
                            />
                            <div>
                              <div
                                className="font-bold text-black font-14 d-flex"
                                style={{ gap: 10 }}
                              >
                                {member?.player?.user?.name || "Guest"}
                              </div>
                              <div className="lightgreyColor font-12">
                                {
                                  member?.player?.committed_to_organization
                                    ?.organization_name
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : linkedPlayers?.map((member, index) => (
                    <div className="parentGreyBox" key={index}>
                      <div className="row d-flex justify-content-between align-items-center">
                        <div className="d-flex px-lg-2">
                          <img
                            src={member?.player?.picture || AvatarIcon}
                            className={"c-pointer memberprofile"}
                          />
                          <div>
                            <div
                              className="font-bold text-black font-14 d-flex"
                              style={{ gap: 10 }}
                            >
                              {member?.player?.user?.name || "Guest"}
                              {!member?.is_confirmed && (
                                <div className="request_pending font-normal font-12 text-center px-1">
                                  Request Pending
                                </div>
                              )}
                            </div>
                            <div className="lightgreyColor font-12">
                              {
                                member?.player?.committed_to_organization
                                  ?.organization_name
                              }
                            </div>
                          </div>
                        </div>
                        <div className="d-flex px-lg-2" style={{ gap: 10 }}>
                          <div
                            className={`text_blue_link font-14 cursor  ${!member.is_confirmed && "disabled-link"
                              }`}
                            onClick={() => {
                              navigate(
                                `/player-profile/${member?.player?.user?.id}`
                              )
                              // navigate(`/player-profile/${user?.id}`)
                            }}
                          >
                            View Profile
                          </div>
                          <div
                            className={`text_blue_link font-14 cursor  ${!member.is_confirmed && "disabled-link"
                              }`}
                            onClick={() => {
                              handleChange("statsVisible", true)
                              handleChange("playerId", {
                                id: member?.player?.id,
                                userID: member?.player?.user?.id,
                                name: member?.player?.user?.name
                              })
                            }}
                          >
                            Add Stats
                          </div>
                          {!member?.is_confirmed && (
                            <div
                              className={`alert_red font-14 cursor`}
                              onClick={() => {
                                declineLinkAction(member?.id)
                              }}
                            >
                              Delete
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                {isOwnProfile && (
                  <div className="member_dotted_Box" style={{ padding: 20 }}>
                    <div
                      className="flex-between font-16 font-medium cursor"
                      style={{ justifyContent: "flex-start", gap: 10 }}
                      onClick={() => handleOpenLink("link")}
                    >
                      <div className="add_bg">
                        <AddIcon fontSize="16" />
                      </div>
                      <div>Link New Player</div>
                    </div>
                  </div>
                )}
              </div>
              <section className="pb-3 mb-0 bg-transparent">
                <Grid item xs={6} md={2}>
                  <div className="text-black font-24 font-500">Activities</div>
                  <Divider className="mt-4" />
                  {posts?.map((post, index) => (
                    <Post
                      key={index}
                      state={state}
                      showComment={showComment}
                      handleChange={handleChange}
                      post={post}
                      videoPlaying={videoPlaying}
                      index={index}
                      allChannels={allChannels}
                      allUsers={allUsers}
                      getUserLoader={getUserLoader}
                    />
                  ))}
                </Grid>
              </section>
            </section>
          </div>
        </>
      )}
      <LinkProfile
        visible={linkVisible}
        loadingRemove={loadingRemove}
        loading={loadingFollow}
        handleClose={handleCloseLink}
        data={allPlayers}
      />

      {
        userData?.player?.sport?.name === "Soccer"
          ?
          <SoccerStats
            visible={statsVisible}
            playerId={playerId}
            handleClose={handleClose}
          />
          :
          userData?.player?.sport?.name === "Baseball"
            ?
            <BaseBallStats
              visible={statsVisible}
              playerId={playerId}
              handleClose={handleClose}
            />
            :
            userData?.player?.sport?.name === "Volleyball"
              ?
              <VolleyBallStats
                visible={statsVisible}
                playerId={playerId}
                handleClose={handleClose}
              />
              :
              userData?.player?.sport?.name === "Football" ?
                <FootBallStats
                  visible={statsVisible}
                  playerId={playerId}
                  handleClose={handleClose}
                /> :
                <AddStats
                  visible={statsVisible}
                  playerId={playerId}
                  handleClose={handleClose}
                />
      }
    </div>
  )
}
